import React from 'react'
import Layout from '../components/layout'

const ContactPage = () => (
  <Layout>
    <div id="main" className="alt">
        <section id="Contact">
            <div className="inner">
                <h1>Contact us</h1>
                <p>We'd love to have a chat about your people projects, whether that be working with your existing team, or building a team for you.</p>
            </div>
        </section>
    </div>
  </Layout>
)

export default ContactPage