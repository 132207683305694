import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section id="contact-2">
            <h2>Say hello, we'd love to chat</h2>
            <form method="post" netlify-honeypot="bot-field" data-netlify="true" action="/src/pages/success.js">
                <input type="hidden" name="bot-field" />
                <p>
                    <label>Your Name: <input type="text" name="name" /></label>
                </p>
                <p>
                    <label>Your Email: <input type="email" name="email" /></label>
                </p>
                <p>
                    <label>Message: <textarea name="message"></textarea></label>
                </p>
                <p>
                    <button type="submit">Send</button>
                </p>
            </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:hello@blockandtackle.it">hello@blockandtackle.it</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <a href="tel:+447789003199">+447789003199</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-bullseye"></span>
                        <h3>Location</h3>
                        <span>Our home is Brighton, UK, but we've got team members around the globe</span>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
